import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Title from "../components/Title";
import { queries } from "../shared/layout";

const Doctors = () => {
  return (
    <Layout>
      <Wrapper className="section">
        <Title title="Lekarze" subtitle="Zaufaj naszym specjalistom już dziś" />
        <div className="cards">
          <div className="card">
            <StaticImage
              src="../images/profilowe-1.jpeg"
              layout="constrained"
              className="img"
              alt="Zdjęcie profilowe"
            />
            <div className="info">
              <div>
                <h4>Lek. wet. Justyna Maciąg - Kuźma</h4>
                <h5>Specjalista Chorób Psów i Kotów</h5>
              </div>
              <p>
                Ukończyła Wydział Medycyny Weterynaryjnej Uniwersytetu
                Przyrodniczego w&nbsp;Lublinie. Jej główne zainteresowania
                z&nbsp;dziedziny weterynarii
                to&nbsp;położnictwo&nbsp;i&nbsp;rozród psów, stomatologia,
                dietetyka oraz choroby gryzoni.
              </p>
            </div>
          </div>
          <div className="card">
            <StaticImage
              src="../images/profilowe-2.jpeg"
              layout="constrained"
              className="img"
              alt="Zdjęcie profilowe"
            />
            <div className="info">
              <h4>Lek. wet. Michał Kuźma</h4>
              <p>
                Ukończył Wydział Medycyny Weterynaryjnej Uniwersytetu
                Przyrodniczego&nbsp;w&nbsp;Lublinie. Interesuje się głównie
                chirurgią weterynaryjną, diagnostyką obrazową oraz chorobami
                zakaźnymi&nbsp;i pasożytniczymi zwierząt.
              </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const Head = () => {
  return <Seo title="Lekarze - Animal" />;
};

const Wrapper = styled.div`
  .cards {
    min-height: 50vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    ${queries.tablet} {
      width: 80%;
    }

    ${queries.desktop} {
      width: 60%;
      max-width: 120rem;
    }
  }

  .card {
    height: max-content;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 30rem 1fr;
    gap: 0;

    ${queries.tablet} {
      height: 30rem;
      grid-template-columns: 30rem 1fr;
      grid-template-rows: 1fr;
      gap: 3rem;
    }
  }

  .img {
    height: 100%;
    width: 100%;
    max-width: 30rem;
    max-height: 30rem;

    border: 0.5rem solid ${({ theme }) => theme.colors.primary};
    border-radius: 1rem;
    justify-self: center;

    ${queries.tablet} {
      max-width: unset;
    }
  }

  .info {
    padding-top: 2rem;
    width: 100%;
    max-width: 50rem;
    justify-self: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    ${queries.tablet} {
      max-width: unset;
      padding: 2rem;
      padding-left: 0;
    }

    h4,
    h5,
    p {
      text-align: center;

      ${queries.tablet} {
        text-align: start;
      }
    }
  }
`;

export default Doctors;
